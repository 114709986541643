import * as React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

export default function NotFoundPage() {
  return (
    <Layout>
      <SEO title="Page Not Found" />

      <div className="my-32">
        <h1 className="font-semibold text-3xl text-center text-[#242D3F]">404: Page Not Found</h1>
      </div>
    </Layout>
  );
}
